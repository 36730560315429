export default {
  methods: {
    async confirmDangerousAction(
      message,
      title = this.trans('booking.default.cancel.confirm.title')
    ) {
      return this.$bvModal.msgBoxConfirm(message, {
        title,
        okVariant: 'danger',
        cancelTitle: this.trans('general.no'),
        hideHeaderClose: false,
        centered: true,
      });
    },
  },
};
